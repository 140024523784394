<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="{ name: 'users' }" class="button">
        Manage Users
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Name" horizontal>
              <b-input
                v-model="form.name"
                placeholder="e.g. AXIS123"
                required
              />
            </b-field>
            <b-field label="Email" v-if="form.is_vacant==0" message="Consumer's email name" horizontal>
              <b-input
                v-model="form.email"
                placeholder="e.g. name@gmail.com"
                required
              />
            </b-field>
            <b-field label="Emp Number" v-if="form.is_vacant==0" message="Consumer's Emp Number" horizontal>
              <b-input
                v-model="form.emp_num"
                placeholder="e.g. emp num"
              />
            </b-field>
            <b-field label="Bill Unit No" horizontal>
              <b-input
                v-model="form.billunitnumber"
                placeholder="e.g. 70542820"
              />
            </b-field>
            <b-field label="Address" horizontal>
              <b-input
                v-model="form.address"
                placeholder="e.g. AXIS123"
              />
            </b-field>
            <b-field label="Quarter" horizontal>
              <b-input
                v-model="form.quarter"
                placeholder="e.g. 751001"
              />
            </b-field>
            <b-field label="Region" horizontal>
              <v-select
                  label="text"
                  placeholder="Search Region"
                  :filterable="false"
                  class="control"
                  :options="regions"
                  @search="onRegionSearch"
                  @input="setRegion">
                    <template slot="no-options">
                    type to search region ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>
            <b-field label="Code" horizontal>
              <v-select
                  label="text"
                  placeholder="Search By Code"
                  :filterable="false"
                  class="style-chooser"
                  @input="setCode"
                  :options="codes"
                  @search="onCodeSearch">
                    <template slot="no-options">
                    type to search code ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>

            <b-field label="Roles" horizontal>
                <v-select multiple  label="value" placeholder="Add more roles" :filterable="false" class="style-chooser" v-model="form.roles" :options="roles" @search="onRoleSearch">
                    <template slot="no-options">
                    type to search roles ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>

            <b-field label="Extra Permissions" message="These permissions will be added to user apart from role based permissions." horizontal>
                <v-select multiple  label="value" placeholder="Add extra permissions" :filterable="false" class="style-chooser" v-model="form.permissions" :options="permissions" @search="onPermissionSearch">
                    <template slot="no-options">
                    type to search permissions ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>
            <!-- <b-field label="Status" horizontal>
                <b-select placeholder="Is Active?" v-model="form.is_active">
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </b-select>
            </b-field> -->
            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="User Details"
          icon="account"
          class="tile is-child"
          header-icon="delete"
          @header-icon-click="trashModal(form)"
        >
          <b-field grouped>
            <b-field label="Id">
                <b-input :value="form.id" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Roles">
              <b-taglist>
                <b-tag v-for="r in form.roles" :key="r.value" type="is-info" closable>
                  {{ r.text }}
                </b-tag>
              </b-taglist>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Name">
                <b-input :value="form.name" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Email">
                <b-input :value="form.email" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Bill Unit No">
                <b-input :value="form.billunitnumber" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Address">
                <b-input :value="form.address" custom-class="is-static" readonly />
            </b-field>
          </b-field>
            <b-field grouped>
                <b-field label="Code" v-if="form.code">
                    <b-input :value="form.code.text" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Region" v-if="form.region">
                    <b-input :value="form.region.text" custom-class="is-static" readonly />
                </b-field>
            </b-field>
            <b-field label="Extra Permissions" v-if="form.permissions.length">
                <b-taglist>
                  <b-tag v-for="per in form.permissions" :key="per.value" type="is-danger" closable>
                      {{per.text}}
                  </b-tag>
                </b-taglist>
            </b-field>
            <b-field label="Created At">
                <b-input
                expanded
                :value="form.created_at"
                custom-class="is-static"
                readonly
                />
            </b-field>
            <b-field label="Updated At">
                <b-input
                expanded
                :value="form.updated_at"
                custom-class="is-static"
                readonly
                />
            </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import ModalBox from '@/components/ModalBox'
import debounce from 'lodash/debounce'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import { apiService } from '@/services'

export default {
  name: 'UserForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ModalBox,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      isModalActive: false,
      trashObject: null,
      createdReadable: null,
      isProfileExists: false,
      roles: [], // Used in options
      permissions: [], // Used in options
      regions: [], // Used in options
      codes: [] // Used in options
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    },
    titleStack () {
      const lastCrumb = this.isProfileExists ? this.form.name : 'New User'
      return ['Admin', 'Users', lastCrumb]
    },
    heroTitle () {
      return this.isProfileExists ? this.form.name : 'Create User'
    },
    formCardTitle () {
      return this.isProfileExists ? 'Edit User' : 'New User'
    },
    ...mapState('user', ['item', 'formError'])
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false
      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('user', ['getItem', 'addItem', 'updateItem', 'deleteItem']),
    getClearFormObject () {
      return {
        id: null,
        name: null,
        email: null,
        emp_num: null,
        billunitnumber: null,
        address: null,
        quarter: null,
        region: null,
        code: null,
        roles: [],
        permissions: [],
        is_vacant: 0,
        created_at: null,
        updated_at: null
      }
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.deleteItem(this.trashObject.id).then(() => {
        this.$router.replace({ name: 'users' })
      })
    },
    trashCancel () {
      this.isModalActive = false
    },
    getData () {
      if (this.$route.params.id) {
        this.getItem(this.$route.params.id).then(() => {
          if (!isEmpty(this.item)) {
            this.isProfileExists = true
            this.form = this.item
          } else {
            this.$router.push({ name: 'user.new' })
          }
        })
      }
    },
    submit () {
      this.isLoading = true
      if (!this.form.id) {
        this.addItem(this.form).then(() => {
          if (!this.formError) this.$router.push({ name: 'users' })
          this.isLoading = false
        }).catch(() => { this.isLoading = false })
      } else {
        this.updateItem(this.form).then(() => {
          this.isLoading = false
          this.form = this.item
        }).catch(() => { this.isLoading = false })
      }
    },
    setRegion (option) {
      this.form.region = option ? option.value : null
    },
    setCode (option) {
      this.form.code = option ? option.value : null
    },
    onRoleSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'roles', search, this)
      }
    },
    onPermissionSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'permissions', search, this)
      }
    },
    onRegionSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'regions', search, this)
      }
    },
    onCodeSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'codes', search, this)
      }
    },
    search: debounce((loading, endpoint, search, vm) => {
      apiService.getAll(`/options/${escape(endpoint)}?q=${escape(search)}`).then(response => {
        vm[endpoint] = response.data
        loading(false)
      }).catch(error => {
        vm.showError(error)
      })
    }, 350)
  }
}
</script>
